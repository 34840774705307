import React, { useEffect } from 'react';
import {
  changeLocale,
  IntlContextConsumer,
  useIntl,
} from 'gatsby-plugin-react-intl';
import { useCookies } from 'react-cookie';

import {
  siteLanguages,
  siteDefaultLanguage,
  siteLocaleDictionary,
} from '../../../config';

import GlobeEuropeRegularIcon from './images/globe-europe-regular.inline.svg';
import ChevronDownRegularIcon from './images/chevron-down-regular.inline.svg';

import * as s from './LanguageSwitcher.module.css';

const COOKIE_NAME = 'lang';

const languageName = {
  en: 'English',
  pl: 'Polski',
  ru: 'pусский',
  tr: 'Türkçe',
  de: 'Deutsch',
  fr: 'français',
  pt: 'português (PT)',
  br: 'português (BR)',
  se: 'svenska',
};

const LanguageSwitcher = () => {
  const intl = useIntl();
  const [, setCookie] = useCookies([COOKIE_NAME]);

  const setLangCookie = () => {
    setCookie('lang', siteLocaleDictionary[intl.locale], {
      path: '/',
      maxAge: 3650 * 24 * 3600, // 10 years in seconds
    });
  };
  const handleLanguageChange = (event) => {
    event.preventDefault();

    if (window.dataLayer) {
      const selectedOption = event.target.options[event.target.selectedIndex];

      window.dataLayer.push({
        event: 'languageSwitch',
        selectedLanguage: selectedOption.innerText,
      });
    }

    changeLocale(event.target.value);
  };

  useEffect(() => {
    setLangCookie();
  }, []);

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <label htmlFor="languageSwitcher" className={s.root} tabIndex={-1}>
          <GlobeEuropeRegularIcon className={s.icon} />
          <span className={s.caption}>{languageName[intl.locale]}</span>
          <ChevronDownRegularIcon className={s.chevron} />
          <select
            id="languageSwitcher"
            value={currentLocale}
            onChange={handleLanguageChange}
          >
            {[siteDefaultLanguage, ...siteLanguages].map((locale) => (
              <option key={locale} value={locale}>
                {languageName[locale]}
              </option>
            ))}
          </select>
        </label>
      )}
    </IntlContextConsumer>
  );
};

export default LanguageSwitcher;
