import cx from 'classnames';
import { Link, defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import SkinwalletLogo from './images/logo-skinwallet.inline.svg';
import { VARIANT as HUB_VARIANT } from '../Hub';
import * as s from './TopBar.module.css';

const messages = defineMessages({
  loginButtonCaption: {
    id: 'topBar.logIn',
    defaultMessage: 'Log In',
  },
  signUpButtonCaption: {
    id: 'topBar.signUp',
    defaultMessage: 'Sign Up',
  },
});

const useHideOnScroll = () => {
  const [isHidden, setIsHidden] = useState(false);
  const prevScrollY = useRef();

  useEffect(() => {
    const onScroll = () => {
      const offset = 60;
      const scrolledDown = window.scrollY > prevScrollY.current;
      const scrolledUp = !scrolledDown;

      if (window.scrollY > offset && scrolledDown && !isHidden) {
        setIsHidden(true);
      } else if (scrolledUp && isHidden) {
        setIsHidden(false);
      }

      prevScrollY.current = window.scrollY;
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isHidden]);

  return isHidden;
};

const TopBar = ({ isHidableOnScroll }) => {
  const { formatMessage } = useIntl();
  const shouldHide = useHideOnScroll();

  return (
    <header id="top-bar" className={s.root}>
      <div
        className={cx(s.bar, {
          [s.isHidden]: isHidableOnScroll ? shouldHide : false,
        })}
      >
        <Link
          id="topbar-logo-button"
          to="/"
          className={s.logo}
          aria-label="Skinwallet"
          role="button"
        >
          <SkinwalletLogo />
        </Link>
        <nav className={s.actions}>
          <Link
            id="topbar-login-button"
            to={`/hub/#${HUB_VARIANT.LOGIN}`}
            className={s.link}
            role="button"
          >
            {formatMessage(messages.loginButtonCaption)}
          </Link>
          <Link
            id="topbar-signup-button"
            to={`/hub/#${HUB_VARIANT.SIGN_UP}`}
            className={s.cta}
            role="button"
          >
            {formatMessage(messages.signUpButtonCaption)}
          </Link>
        </nav>
      </div>
    </header>
  );
};

TopBar.propTypes = {
  isHidableOnScroll: PropTypes.bool.isRequired,
};

export default TopBar;
