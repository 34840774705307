import React from 'react';

import AirtmLogo from './images/airtm.inline.svg';
import AmazonLogo from './images/amazon.inline.svg';
import BitcoinLogo from './images/bitcoin.inline.svg';
import ConotoxiaPayLogo from './images/conotoxia-pay.inline.svg';
import ConotoxiaLogo from './images/conotoxia.inline.svg';
import G2APayLogo from './images/g2apay.inline.svg';
import MastercardLogo from './images/mastercard.inline.svg';
import PayeerLogo from './images/payeer.inline.svg';
import PayoneerLogo from './images/payoneer.inline.svg';
import SkrillLogo from './images/skrill.inline.svg';
import TetherLogo from './images/tether.inline.svg';
import TipaltiLogo from './images/tipalti.inline.svg';
import UnionPayLogo from './images/union-pay.inline.svg';
import VanillaVisaLogo from './images/vanilla-visa.inline.svg';
import VisaLogo from './images/visa.inline.svg';
import WebMoneyLogo from './images/webmoney.inline.svg';

export const PROVIDER = {
  PAYEER: {
    name: 'Payeer',
    logo: <PayeerLogo />,
    slug: 'payeer',
  },
  TIPALTI: {
    name: 'Tipalti',
    logo: <TipaltiLogo />,
    slug: 'tipalti',
  },
  PAYONEER: {
    name: 'Payoneer',
    logo: <PayoneerLogo />,
    slug: 'payoneer',
  },
  AIRTM: {
    name: 'Airtm',
    logo: <AirtmLogo />,
    slug: 'airtm',
  },
  CONOTOXIA: {
    name: 'Conotoxia',
    logo: <ConotoxiaLogo />,
    slug: 'conotoxia',
  },
  CONOTOXIA_PAY: {
    name: 'Conotoxia Pay',
    logo: <ConotoxiaPayLogo />,
    slug: 'conotoxiaPay',
  },
  MASTERCARD: {
    name: 'Mastercard',
    logo: <MastercardLogo />,
    slug: 'mastercard',
  },
  VISA: {
    name: 'VISA',
    logo: <VisaLogo />,
    slug: 'visa',
  },
  G2A_PAY: {
    name: 'G2A Pay',
    logo: <G2APayLogo />,
    slug: 'g2aPay',
  },
  AMAZON_GIFT_CARDS: {
    name: 'Amazon Gift Cards',
    logo: <AmazonLogo />,
    slug: 'amazonGiftCards',
  },
  VANILLA_VISA: {
    name: 'Vanilla Visa',
    logo: <VanillaVisaLogo />,
    slug: 'vanillaVisa',
  },
  TETHER: {
    name: 'Tether',
    logo: <TetherLogo />,
    slug: 'tether',
  },
  UNION_PAY: {
    name: 'Union Pay',
    logo: <UnionPayLogo />,
    slug: 'unionPay',
  },
  WEB_MONEY: {
    name: 'Web Money',
    logo: <WebMoneyLogo />,
    slug: 'webMoney',
  },
  BITCOIN: {
    name: 'bitcoin',
    logo: <BitcoinLogo />,
    slug: 'bitcoin',
  },
  SKRILL: {
    name: 'Skrill',
    logo: <SkrillLogo />,
    slug: 'skrill',
  },
};

export const GAME = {
  CSGO: 'CSGO',
  DOTA2: 'DOTA2',
  TF2: 'TF2',
};

export const GAMES = [GAME.CSGO, GAME.DOTA2, GAME.TF2];

export const BLOG_URL = {
  [GAME.CSGO]: '/csgo',
  [GAME.DOTA2]: '/dota-2',
  [GAME.TF2]: '/tf2-items',
};

export const BLOG_LANG = {
  [GAME.CSGO]: ['pl', 'ru', 'br', 'de'],
  [GAME.DOTA2]: ['ru'],
  [GAME.TF2]: [],
};
