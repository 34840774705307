// extracted by mini-css-extract-plugin
export var copyright = "Footer-module--copyright--2GRbV";
export var copyrightSection = "Footer-module--copyrightSection--Ak9wa";
export var emptySection = "Footer-module--emptySection--wdxSX";
export var extraNavigation = "Footer-module--extraNavigation--pSolk";
export var languageSwitcherSection = "Footer-module--languageSwitcherSection--ZoFZB";
export var legalNavigation = "Footer-module--legalNavigation--jSADu";
export var mainNavigation = "Footer-module--mainNavigation--DYZTH";
export var navigation = "Footer-module--navigation--dNI3H";
export var navigationSection = "Footer-module--navigationSection--2IQow";
export var root = "Footer-module--root--Q8fNK";
export var socialMedia = "Footer-module--socialMedia--XpIuW";
export var socialMediaSection = "Footer-module--socialMediaSection--n9VaT";
export var steamCopyright = "Footer-module--steamCopyright--a46RA";
export var steamCopyrightSection = "Footer-module--steamCopyrightSection--t7YZ+";