import PropTypes from 'prop-types';
import React from 'react';

import { GAME } from '../../constants';
import CookieBanner from '../CookieBanner';
import Footer from '../Footer';
import TopBar from '../TopBar';
import * as s from './Layout.module.css';

const Layout = ({
  tagName,
  game,
  shouldHideOnScroll,
  isFooterVisible,
  children,
}) => {
  const TagName = tagName;
  return (
    <>
      <CookieBanner />
      <TopBar isHidableOnScroll={shouldHideOnScroll} />
      <TagName id="pageContent" className={s.content}>
        {children}
      </TagName>
      {isFooterVisible && <Footer game={game} />}
    </>
  );
};

Layout.propTypes = {
  tagName: PropTypes.string,
  game: PropTypes.oneOf(Object.keys(GAME)),
  shouldHideOnScroll: PropTypes.bool,
  isFooterVisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  tagName: 'main',
  shouldHideOnScroll: true,
  isFooterVisible: true,
  game: GAME.CSGO,
};

Footer.propTypes = {};

export default Layout;
